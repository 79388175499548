import styled from 'styled-components';

import { Hashtag } from 'styled-icons/heroicons-outline';

export default {
    Container: styled.div`
        grid-area: CI;
        display: flex;
        align-items: center;
        padding: 0 17px;
        background-color: var(--primary);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px 0px;
        z-index: 2;
    `,
    HashtagIcon: styled(Hashtag)`
        width: 24px;
        height: 24px;
        color: var(--symbol);
    `,
    Title: styled.div`
        margin-left: 9px;
        font-size: 16px;
        font-weight: bold;
        color: var(--white);
    `,
    Divider: styled.div`
        height: 24px;
        width: 1px;
        background-color: var(--white);
        opacity: 0.2;
        margin: 0 13px;
    `,
    Description: styled.span`
        font-size: 15px;
        color: var(--gray);
    `,
}